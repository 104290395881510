@import url(https://fonts.googleapis.com/css?family=Montserrat|Oswald:300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-color: none;
  margin: 0;
  padding: 0;
}

.Section {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

/*Home Styling*/

.Home {
  align-items: center;
  justify-content: center;
  background-image: url(/static/media/background.1c805388.png);
  background-position: bottom;
  background-size: cover;
  flex-wrap: wrap;
  flex-direction: column;
}

.mainLogo {
  width: 30%;
}

h1 {
  font-weight: 300;
  font-size: 22px;
  margin-top: 44px;
  font-family: "Oswald", sans-serif;
}

/*About Styling*/

.AboutText {
  width: 60%;
  height: 50%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-color: black;
  border-width: 4px;
  border-radius: 2px;
  padding-top: 44px;
  padding-bottom: 44px;
}

h2 {
  font-family: "Montserrat", sans-serif;
  padding: 0;
  font-size: 18px;
}

.line {
  margin-bottom: 22px;
}

.intro {
  width: 75%;
  font-size: 16px;
  line-height: 26px;
  font-family: "Oswald", sans-serif;
  text-align: center;
}

.icon {
  margin-bottom: 8px;
}

/*Barber Section*/
.Barbers {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.barbertitle {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 22px;
}

.barberpros {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 70%;
  padding-bottom: 0px;
}

.profile {
  width: 22%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin: 2%;
  margin-top: 0%;
  min-height: 100px;
}

.proImg {
  width: 125px;
  height: 125px;
  border-radius: 75px;
  margin-bottom: 22px;
  background-position: center;
  background-size: cover;
}

.Creditp {
  font-size: 10px;
  justify-self: flex-end;
  margin-top: 8%;
}

.Sandra {
  background-image: url(/static/media/Sandra.af48a2c0.png);
  background-position: left;
}

.Linda {
  background-image: url(/static/media/Linda.5d913da4.png);
  background-position: center;
}

.Jordan {
  background-image: url(/static/media/Jordan.f804d480.png);
  background-position: left;
}

.Robert {
  background-image: url(/static/media/Robert.9b9a5ce2.png);
  background-position: center;
}

.proText {
  font-family: "Oswald", sans-serif;
  margin-bottom: 4px;
  font-size: 16px;
  line-height: 26px;
  padding: 0;
  width: 100%;
  text-align: center;
}

.Services {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #212121;
}

.BookButton {
  background-color: white;
  color: #212121;
  border-width: 2px;
  border-radius: 4px;
  padding-top: 4px;
  padding-bottom: 6px;
  padding-left: 16px;
  padding-right: 16px;
}

.ServContent {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-style: solid;
  border-color: white;
  border-width: 4px;
  border-radius: 2px;
  color: white;
  padding: 50px;
}

.ServText {
  color: white;
  font-family: "Oswald", sans-serif;
  width: 60%;
  text-align: center;
}

.listItems {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-top: 16px;
  margin-bottom: 16px;
}

li {
  padding-right: 22px;
}

.contactinfo {
  width: 100%;
  padding-top: 22px;
  display: flex;
  flex-direction: row;
  line-height: 26px;
  font-family: "Oswald", sans-serif;
}

.contInfo {
  margin-left: 36px;
  font-size: 22px;
}

.map {
  width: 100%;
}

.CContent {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  padding-top: 44px;
  padding-bottom: 44px;
}

.CSection {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.conInfo {
  font-family: "Oswald", sans-serif;
  text-align: center;
  line-height: 26px;
  width: 60%;
}

.footer {
  width: 100vw;
  min-height: 200px;
  background-color: #212121;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.footext {
  color: white;
  text-align: center;
  font-family: "Oswald", sans-serif;
  line-height: 26px;
}

.copy {
  font-size: 10px;
  color: white;
}

/* Navigation */

.menuButton {
  position: fixed;
  z-index: 9999;
  margin-left: 16px;
  margin-top: 16px;
}

#nav {
  position: fixed;
  width: 15%;
  height: 100vh;
  background-color: #121212;
  margin-left: -16%;
  -webkit-transition: ease-in-out 250ms;
  transition: ease-in-out 250ms;
  color: white;
  display: flex;
  justify-content: center;
  text-decoration: none;
  box-shadow: 0px 0px 6px 0px #88888811;
}

.navlist {
  margin-top: 100px;
  list-style: none;
  padding: 0;
  list-style-type: none;
  text-decoration: none;
}

.navLink {
  list-style: none;
  height: 52px;
  text-decoration: none;
}

a {
  text-decoration: none;
  color: white;
  font-family: "Oswald", sans-serif;
  width: 100%;
  -webkit-transition: ease-in-out 150ms;
  transition: ease-in-out 150ms;
}

a:hover {
  color: #727272;
}

.BookButton:hover {
  background-color: #727272;
  color: #212121;
  border-width: 2px;
  border-radius: 4px;
  padding-top: 4px;
  padding-bottom: 6px;
  padding-left: 16px;
  padding-right: 16px;
}

.conLink {
  color: #212121;
}

.expand {
  margin-left: 0% !important;
}

.Button {
  margin-top: 36px;
}

/*Menu Button*/

.ugb-nav-icon {
  height: 48px;
  position: fixed;
  z-index: 99999;
  margin-top: 16px;
  margin-left: 16px;
}

.ugb-nav-icon > div {
  position: absolute;
  z-index: 3;
  top: 0;
  width: 48px;
  height: 48px;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition: -webkit-transform 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition: transform 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition: transform 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99), -webkit-transform 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  background-color: rgba(255, 255, 255, 0.349);
  border-radius: 24px;
  box-shadow: 0px 0px 6px 0px #2222221c;
}

.ugb-nav-icon .nline {
  display: block;
  width: 17px;
  height: 1px;
  background: #111;
  position: absolute;
  left: 16px;
  z-index: 1;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.ugb-nav-icon > div > span {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 48px;
  height: 48px;
}

.ugb-nav-icon .b1 {
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition: -webkit-transform 0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition: transform 0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition: transform 0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99), -webkit-transform 0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  -webkit-transform: rotate(0);
          transform: rotate(0);
  z-index: 4;
}
.ugb-nav-icon .b1 > span {
  top: 23px;
  -webkit-transition: -webkit-transform 0.2s 0.2s;
  transition: -webkit-transform 0.2s 0.2s;
  transition: transform 0.2s 0.2s;
  transition: transform 0.2s 0.2s, -webkit-transform 0.2s 0.2s;
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
}
.ugb-nav-icon .b2 {
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition: -webkit-transform 0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition: transform 0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition: transform 0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99), -webkit-transform 0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  -webkit-transform: rotate(0);
          transform: rotate(0);
}
.ugb-nav-icon .b2 > span {
  bottom: 23px;
  -webkit-transform: translateY(3px);
          transform: translateY(3px);
}

.ugb-nav-icon.active > div {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.ugb-nav-icon.active .b1 {
  -webkit-transition: -webkit-transform 0.25s 0.2s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition: -webkit-transform 0.25s 0.2s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition: transform 0.25s 0.2s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition: transform 0.25s 0.2s cubic-bezier(0.4, 0.01, 0.165, 0.99), -webkit-transform 0.25s 0.2s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.ugb-nav-icon.active .b1 > span {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.ugb-nav-icon.active .b2 {
  -webkit-transition: -webkit-transform 0.25s 0.2s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition: -webkit-transform 0.25s 0.2s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition: transform 0.25s 0.2s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition: transform 0.25s 0.2s cubic-bezier(0.4, 0.01, 0.165, 0.99), -webkit-transform 0.25s 0.2s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.ugb-nav-icon.active .b2 > span {
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
  body {
    background-color: none;
  }

  .Section {
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
  }

  .mainLogo {
    width: 45%;
  }

  h1 {
    font-weight: 300;
    font-size: 22px;
    margin-top: 44px;
    font-family: "Oswald", sans-serif;
  }

  .AboutText {
    width: 90%;
    height: 50%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-color: black;
    border-width: 4px;
    border-radius: 2px;
    padding-top: 44px;
    padding-bottom: 44px;
  }

  h2 {
    font-family: "Montserrat", sans-serif;
    padding: 0;
    font-size: 18px;
  }

  .intro {
    width: 85%;
    font-size: 16px;
    line-height: 26px;
    font-family: "Oswald", sans-serif;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }

  .profileContent {
    min-height: 100px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    margin-top: 2px;
  }

  .barberpros {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding-bottom: 0px;
  }

  .profile {
    width: 20%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    margin: 2%;
    margin-top: 0%;
  }

  .ServContent {
    width: 80%;
    min-height: 70%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-style: solid;
    border-color: white;
    border-width: 4px;
    border-radius: 2px;
    color: white;
    padding: 0px;
  }

  .ServText {
    color: white;
    font-family: "Oswald", sans-serif;
    width: 70%;
    text-align: center;
  }

  #nav {
    position: fixed;
    width: 25%;
    height: 100vh;
    background-color: #121212;
    margin-left: -25%;
    -webkit-transition: ease-in-out 250ms;
    transition: ease-in-out 250ms;
    color: white;
    display: flex;
    justify-content: center;
    text-decoration: none;
    box-shadow: 0px 0px 6px 0px #88888811;
  }

  .Button {
    margin-top: 36px;
    margin-bottom: 36px;
  }

  .CSection {
    width: 100vw;
    min-height: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  body {
    background-color: none;
  }
  .Section {
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    overflow: hidden;
  }

  .mainLogo {
    width: 60%;
  }

  h1 {
    font-weight: 300;
    font-size: 18px;
    margin-top: 44px;
    font-family: "Oswald", sans-serif;
  }

  .AboutText {
    width: 90%;
    height: 50%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-color: black;
    border-width: 2px;
    border-radius: 2px;
    padding-top: 44px;
    padding-bottom: 44px;
  }

  h2 {
    font-family: "Montserrat", sans-serif;
    padding: 0;
    font-size: 16px;
  }

  .intro {
    width: 85%;
    font-size: 14px;
    line-height: 26px;
    font-family: "Oswald", sans-serif;
  }

  .barberpros {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 88px;
  }

  .profile {
    width: 80%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    margin: 2%;
    margin-top: 10%;
  }

  .ServContent {
    width: 90%;
    min-height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-style: solid;
    border-color: white;
    border-width: 2px;
    border-radius: 2px;
    color: white;
    padding: 0;
    margin: 0;
    padding: 0px;
  }

  .ServText {
    color: white;
    font-family: "Oswald", sans-serif;
    width: 70%;
    text-align: center;
  }

  .ServText {
    color: white;
    font-family: "Oswald", sans-serif;
    width: 80%;
    text-align: center;
  }

  .conInfo {
    font-family: "Oswald", sans-serif;
    text-align: center;
    line-height: 26px;
    width: 80%;
  }

  .footext {
    color: white;
    text-align: center;
    font-family: "Oswald", sans-serif;
    line-height: 26px;
    width: 80%;
  }

  .copy {
    font-size: 10px;
    color: white;
  }
  #nav {
    position: fixed;
    width: 50%;
    height: 100vh;
    background-color: #121212;
    margin-left: -50%;
    -webkit-transition: ease-in-out 250ms;
    transition: ease-in-out 250ms;
    color: white;
    display: flex;
    justify-content: center;
    text-decoration: none;
    box-shadow: 0px 0px 6px 0px #88888811;
  }

  .Button {
    margin-top: 22px;
    margin-bottom: 22px;
  }

  .CSection {
    width: 100vw;
    min-height: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}

